import React from 'react'
import { motion } from 'framer-motion'
import { FiUsers, FiSearch, FiTrendingUp, FiShield } from 'react-icons/fi'

const HRServices = () => {
  const services = [
    {
      icon: <FiSearch className="w-6 h-6" />,
      title: 'Recruitment & Selection',
      description: 'Expert talent acquisition and placement services to find the perfect match for your organization.',
      features: [
        'Job Analysis & Description Development',
        'Candidate Sourcing & Screening',
        'Interview Process Management',
        'Background Verification'
      ]
    },
    {
      icon: <FiUsers className="w-6 h-6" />,
      title: 'HR Consulting',
      description: 'Strategic HR consulting to optimize your workforce management and organizational development.',
      features: [
        'HR Policy Development',
        'Performance Management Systems',
        'Employee Relations',
        'Compensation & Benefits Planning'
      ]
    },
    {
      icon: <FiTrendingUp className="w-6 h-6" />,
      title: 'Talent Development',
      description: 'Comprehensive programs to develop and retain top talent within your organization.',
      features: [
        'Career Development Planning',
        'Succession Planning',
        'Leadership Development',
        'Employee Engagement Programs'
      ]
    },
    {
      icon: <FiShield className="w-6 h-6" />,
      title: 'HR Compliance',
      description: 'Ensure your organization stays compliant with all relevant employment laws and regulations.',
      features: [
        'Regulatory Compliance Audits',
        'Policy Reviews & Updates',
        'Employment Law Guidance',
        'Safety & Workplace Standards'
      ]
    }
  ]

  return (
    <div className="min-h-screen pt-20">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-primary-600 to-primary-700 text-white py-16 md:py-24">
        <div className="container mx-auto px-4">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl md:text-5xl font-bold mb-6"
          >
            HR & Recruitment Services
          </motion.h1>
          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="text-xl md:text-2xl text-white/90 max-w-2xl"
          >
            Comprehensive HR solutions to help you build and manage high-performing teams
          </motion.p>
        </div>
      </div>

      {/* Services Grid */}
      <div className="py-16 md:py-24 bg-gray-50">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {services.map((service, index) => (
              <motion.div
                key={service.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white rounded-lg shadow-md p-6"
              >
                <div className="flex items-center mb-4">
                  <div className="p-2 bg-primary-100 rounded-lg text-primary-600 mr-4">
                    {service.icon}
                  </div>
                  <h3 className="text-xl font-semibold">{service.title}</h3>
                </div>
                <p className="text-gray-600 mb-4">{service.description}</p>
                <ul className="space-y-2">
                  {service.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="flex items-center text-gray-700">
                      <span className="w-1.5 h-1.5 bg-primary-500 rounded-full mr-2"></span>
                      {feature}
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="py-16 bg-white">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Workforce?</h2>
          <p className="text-gray-600 mb-8 max-w-2xl mx-auto">
            Let's discuss how our HR services can help you build and maintain a high-performing team that drives your business forward.
          </p>
          <motion.a
            href="/contact"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="inline-block bg-primary-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-primary-700 transition-colors"
          >
            Schedule a Consultation
          </motion.a>
        </div>
      </div>
    </div>
  )
}

export default HRServices