import React, { useState, useEffect, useCallback } from 'react'
import { Routes, Route } from 'react-router-dom'
import PropTypes from 'prop-types'

// Layout Components
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'

// Pages
import Home from './pages/Home'
import Services from './pages/Services'
import About from './pages/About'
import Contact from './pages/Contact'
import NotFound from './pages/NotFound'
import CaseStudies from './pages/CaseStudies'
import Careers from './pages/Careers'
import Blog from './pages/Blog'

// Service Pages
import SoftwareDev from './pages/services/SoftwareDev'
import Consulting from './pages/services/Consulting'
import Training from './pages/services/Training'
import Support from './pages/services/Support'
import HRServices from './pages/services/HRServices'

// Legal Pages
import PrivacyPolicy from './pages/legal/PrivacyPolicy'
import TermsOfService from './pages/legal/TermsOfService'

// Blog Posts
import BlogPost1 from './pages/blog/BlogPost1'
import BlogPost2 from './pages/blog/BlogPost2'
import BlogPost3 from './pages/blog/BlogPost3'

function App() {
  const [scrolled, setScrolled] = useState(false)

  const handleScroll = useCallback(() => {
    const isScrolled = window.scrollY > 50
    if (isScrolled !== scrolled) {
      setScrolled(isScrolled)
    }
  }, [scrolled])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return (
    <div className="min-h-screen flex flex-col">
      <Header scrolled={scrolled} />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/software-development" element={<SoftwareDev />} />
          <Route path="/services/consulting" element={<Consulting />} />
          <Route path="/services/hr-services" element={<HRServices />} />
          <Route path="/services/training" element={<Training />} />
          <Route path="/services/support" element={<Support />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/case-studies" element={<CaseStudies />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/future-of-hr-recruitment" element={<BlogPost1 />} />
          <Route path="/blog/effective-training-strategies" element={<BlogPost2 />} />
          <Route path="/blog/custom-software-solutions" element={<BlogPost3 />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </main>
      <Footer />
    </div>
  )
}

App.propTypes = {
  scrolled: PropTypes.bool
}

export default App