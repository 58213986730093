import React from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { FiTarget, FiTrendingUp, FiUsers, FiSettings } from 'react-icons/fi'

// Add these animation variants
const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
}

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
}

const Consulting = () => {
  // Add this ref
  const [headerRef, headerInView] = useInView({ threshold: 0.1, triggerOnce: true });
  
  const services = [
    {
      icon: <FiTarget className="w-8 h-8" />,
      title: "Strategic Consulting",
      description: "Comprehensive business strategy and growth planning to help your organization achieve its goals.",
      features: [
        "Business strategy development",
        "Market analysis",
        "Growth planning",
        "Competitive positioning"
      ]
    },
    {
      icon: <FiTrendingUp className="w-8 h-8" />,
      title: "Digital Transformation",
      description: "Guide your organization through digital transformation initiatives to stay competitive in the modern market.",
      features: [
        "Digital strategy",
        "Process optimization",
        "Technology adoption",
        "Change management"
      ]
    },
    {
      icon: <FiUsers className="w-8 h-8" />,
      title: "Organizational Development",
      description: "Improve your organization's effectiveness through better structure, culture, and leadership.",
      features: [
        "Organizational design",
        "Culture transformation",
        "Leadership development",
        "Team effectiveness"
      ]
    },
    {
      icon: <FiSettings className="w-8 h-8" />,
      title: "Operations Consulting",
      description: "Optimize your business operations for maximum efficiency and profitability.",
      features: [
        "Process improvement",
        "Supply chain optimization",
        "Cost reduction",
        "Quality management"
      ]
    }
  ]

  return (
    <div className="pt-20">
      {/* Header Section */}
      <section className="bg-primary-600 text-white py-16 md:py-24">
        <div className="container">
          <motion.div 
            ref={headerRef}
            initial="hidden"
            animate={headerInView ? "visible" : "hidden"}
            variants={staggerContainer}
            className="max-w-3xl mx-auto text-center"
          >
            <motion.h1 variants={fadeIn} className="text-4xl md:text-5xl font-bold mb-6">
              Consulting Services
            </motion.h1>
            <motion.p variants={fadeIn} className="text-lg md:text-xl text-white/80 mb-8">
              Strategic guidance and solutions for business excellence
            </motion.p>
          </motion.div>
        </div>
      </section>

      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-bold mb-4">Business Consulting Services</h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Expert guidance to help your business grow and succeed in today's competitive market.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          {services.map((service, index) => (
            <motion.div
              key={service.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white rounded-lg shadow-lg p-6"
            >
              <div className="text-primary-600 mb-4">
                {service.icon}
              </div>
              <h3 className="text-xl font-bold mb-3">{service.title}</h3>
              <p className="text-gray-600 mb-4">{service.description}</p>
              <ul className="space-y-2">
                {service.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center">
                    <svg className="w-5 h-5 mr-2 text-primary-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="bg-primary-600 text-white rounded-lg p-8 text-center"
        >
          <h2 className="text-2xl font-bold mb-4">Ready to Transform Your Business?</h2>
          <p className="text-white/80 mb-6">
            Let's discuss how our consulting services can help your organization achieve its full potential.
          </p>
          <button className="btn btn-accent">
            Schedule a Consultation
          </button>
        </motion.div>
      </div>
    </div>
  )
}

export default Consulting 