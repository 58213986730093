import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { Link } from 'react-router-dom'
import { FiAward, FiBarChart, FiFilter } from 'react-icons/fi'

// Animation variants
const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
}

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
}

// Case studies data
const caseStudies = [
  {
    id: 1,
    title: "Digital Transformation Success",
    client: "TechCorp Inc.",
    industry: "Technology",
    description: "Helped a mid-sized tech company transform their HR processes through digital solutions.",
    results: [
      "50% reduction in HR processing time",
      "30% increase in employee satisfaction",
      "25% cost savings in HR operations"
    ],
    image: "/images/case-studies/digital-transformation.jpg"
  },
  {
    id: 2,
    title: "Global Talent Acquisition",
    client: "Global Solutions Ltd.",
    industry: "Consulting",
    description: "Implemented a comprehensive talent acquisition strategy for a global consulting firm.",
    results: [
      "40% faster time-to-hire",
      "45% reduction in recruitment costs",
      "Improved candidate quality"
    ],
    image: "/images/case-studies/talent-acquisition.jpg"
  },
  {
    id: 3,
    title: "Training Program Excellence",
    client: "Manufacturing Co.",
    industry: "Manufacturing",
    description: "Developed and delivered a custom training program for a manufacturing company.",
    results: [
      "60% increase in employee productivity",
      "35% reduction in workplace accidents",
      "Improved employee retention"
    ],
    image: "/images/case-studies/training-excellence.jpg"
  }
]

// Filter categories
const categories = ['All', 'HR Services', 'Software Development', 'Training']

const CaseStudies = () => {
  const [selectedCategory, setSelectedCategory] = useState('All')
  const [headerRef, headerInView] = useInView({ threshold: 0.1, triggerOnce: true })
  const [studiesRef, studiesInView] = useInView({ threshold: 0.1, triggerOnce: true })

  // Filter case studies based on selected category
  const filteredStudies = selectedCategory === 'All'
    ? caseStudies
    : caseStudies.filter(study => study.industry === selectedCategory)

  return (
    <div className="pt-24 pb-16">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-bold mb-4">Case Studies</h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Discover how we've helped businesses transform their operations and achieve remarkable results through our comprehensive solutions.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredStudies.map((study, index) => (
            <motion.div
              key={study.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <div className="aspect-w-16 aspect-h-9 bg-gray-200">
                <img
                  src={study.image}
                  alt={study.title}
                  className="object-cover w-full h-full"
                />
              </div>
              <div className="p-6">
                <h3 className="text-xl font-bold mb-2">{study.title}</h3>
                <p className="text-gray-600 mb-4">
                  <span className="font-semibold">{study.client}</span> | {study.industry}
                </p>
                <p className="text-gray-700 mb-4">{study.description}</p>
                <ul className="space-y-2">
                  {study.results.map((result, idx) => (
                    <li key={idx} className="flex items-center text-green-600">
                      <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      {result}
                    </li>
                  ))}
                </ul>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default CaseStudies