import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FiMenu, FiX, FiChevronDown } from 'react-icons/fi'
import { motion, AnimatePresence } from 'framer-motion'
import Logo from '../ui/Logo'

const ServiceSubmenu = ({ scrolled }) => {
  return (
    <div className="absolute top-full left-1/2 transform -translate-x-1/2 w-64 py-2 mt-0.5 bg-white rounded-lg shadow-lg">
      <div className="absolute top-0 left-0 w-full h-2 -translate-y-full" />
      <Link
        to="/services/software-development"
        className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100"
      >
        Software Development
      </Link>
      <Link
        to="/services/consulting"
        className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100"
      >
        Consulting
      </Link>
      <Link
        to="/services/hr-services"
        className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100"
      >
        HR Services
      </Link>
      <Link
        to="/services/training"
        className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100"
      >
        Training
      </Link>
      <Link
        to="/services/support"
        className="block px-4 py-2 text-sm text-gray-800 hover:bg-gray-100"
      >
        Support
      </Link>
    </div>
  )
}

const NavItem = ({ to, children, scrolled, hasSubmenu = false }) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const location = useLocation()
  const isHome = location.pathname === '/'
  
  return (
    <div 
      className="relative group"
      onMouseEnter={() => hasSubmenu && setIsSubmenuOpen(true)}
      onMouseLeave={(e) => {
        // Check if we're not hovering over the submenu
        const submenu = e.currentTarget.querySelector('[role="menu"]')
        if (submenu && !submenu.contains(e.relatedTarget)) {
          setIsSubmenuOpen(false)
        }
      }}
    >
      <Link
        to={to}
        className={`text-sm font-medium transition-colors duration-200 ${
          isHome 
            ? scrolled 
              ? 'text-gray-600 hover:text-primary-600' 
              : 'text-white hover:text-white/80'
            : scrolled 
              ? 'text-gray-600 hover:text-primary-600' 
              : 'text-gray-800 hover:text-primary-600'
        }`}
      >
        <span className="flex items-center">
          {children}
          {hasSubmenu && (
            <FiChevronDown className="ml-1 w-4 h-4" />
          )}
        </span>
      </Link>
      {hasSubmenu && (
        <div 
          className={`${isSubmenuOpen ? 'block' : 'hidden'} absolute top-full left-1/2 transform -translate-x-1/2`}
          role="menu"
          onMouseEnter={() => setIsSubmenuOpen(true)}
          onMouseLeave={() => setIsSubmenuOpen(false)}
        >
          <ServiceSubmenu scrolled={scrolled} />
        </div>
      )}
    </div>
  )
}

const MobileNavItem = ({ to, children, scrolled, hasSubmenu = false }) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const location = useLocation()
  const isHome = location.pathname === '/'
  
  return (
    <div>
      <div
        className="flex items-center justify-between"
        onClick={() => hasSubmenu && setIsSubmenuOpen(!isSubmenuOpen)}
      >
        <Link
          to={to}
          className={`block py-2 text-base font-medium transition-colors duration-200 ${
            isHome 
              ? scrolled 
                ? 'text-gray-600 hover:text-primary-600' 
                : 'text-white hover:text-white/80'
              : scrolled 
                ? 'text-gray-600 hover:text-primary-600' 
                : 'text-gray-800 hover:text-primary-600'
          }`}
        >
          <span className="flex items-center">
            {children}
            {hasSubmenu && (
              <FiChevronDown className={`ml-1 w-4 h-4 transform transition-transform ${isSubmenuOpen ? 'rotate-180' : ''}`} />
            )}
          </span>
        </Link>
      </div>
      {hasSubmenu && isSubmenuOpen && (
        <div className="pl-4 mt-2 space-y-2">
          <Link
            to="/services/software-development"
            className="block py-2 text-sm text-gray-600 hover:text-primary-600"
          >
            Software Development
          </Link>
          <Link
            to="/services/consulting"
            className="block py-2 text-sm text-gray-600 hover:text-primary-600"
          >
            Consulting
          </Link>
          <Link
            to="/services/hr-services"
            className="block py-2 text-sm text-gray-600 hover:text-primary-600"
          >
            HR Services
          </Link>
          <Link
            to="/services/training"
            className="block py-2 text-sm text-gray-600 hover:text-primary-600"
          >
            Training
          </Link>
          <Link
            to="/services/support"
            className="block py-2 text-sm text-gray-600 hover:text-primary-600"
          >
            Support
          </Link>
        </div>
      )}
    </div>
  )
}

NavItem.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  scrolled: PropTypes.bool.isRequired,
  hasSubmenu: PropTypes.bool
}

MobileNavItem.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  scrolled: PropTypes.bool.isRequired,
  hasSubmenu: PropTypes.bool
}

ServiceSubmenu.propTypes = {
  scrolled: PropTypes.bool.isRequired
}

const Header = ({ scrolled }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const location = useLocation()
  const isHome = location.pathname === '/'

  const logoColor = isHome 
    ? scrolled ? 'dark' : 'light'
    : 'dark'

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const isActive = (path) => {
    return location.pathname === path
  }

  return (
    <header
      className={`fixed w-full z-50 transition-all duration-300 ${
        scrolled ? 'bg-white shadow-md' : 'bg-transparent'
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-20">
          <Link to="/" className="flex-shrink-0">
            <Logo color={logoColor} />
          </Link>

          {/* Desktop Navigation */}
          <nav className="hidden md:flex items-center space-x-8">
            <NavItem to="/" scrolled={scrolled}>
              Home
            </NavItem>
            <NavItem to="/services" scrolled={scrolled} hasSubmenu={true}>
              Services
            </NavItem>
            <NavItem to="/case-studies" scrolled={scrolled}>
              Case Studies
            </NavItem>
            <NavItem to="/about" scrolled={scrolled}>
              About
            </NavItem>
            <NavItem to="/careers" scrolled={scrolled}>
              Careers
            </NavItem>
            <NavItem to="/blog" scrolled={scrolled}>
              Blog
            </NavItem>
            <Link
              to="/contact"
              className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
                scrolled
                  ? 'bg-primary-600 text-white hover:bg-primary-700'
                  : 'bg-white text-primary-600 hover:bg-white/90'
              }`}
            >
              Contact Us
            </Link>
          </nav>

          {/* Mobile Menu Button */}
          <button
            className="md:hidden p-2 rounded-md focus:outline-none"
            onClick={toggleMenu}
            aria-label="Toggle menu"
            aria-expanded={isMenuOpen}
            aria-controls="mobile-menu"
          >
            {isMenuOpen ? (
              <FiX className={`w-6 h-6 ${scrolled ? 'text-gray-600' : 'text-white'}`} />
            ) : (
              <FiMenu className={`w-6 h-6 ${scrolled ? 'text-gray-600' : 'text-white'}`} />
            )}
          </button>
        </div>

        {/* Mobile Navigation */}
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.2 }}
              className="md:hidden bg-white"
              id="mobile-menu"
            >
              <div className="px-2 pt-2 pb-3 space-y-1">
                <MobileNavItem to="/" scrolled={scrolled}>
                  Home
                </MobileNavItem>
                <MobileNavItem to="/services" scrolled={scrolled} hasSubmenu={true}>
                  Services
                </MobileNavItem>
                <MobileNavItem to="/case-studies" scrolled={scrolled}>
                  Case Studies
                </MobileNavItem>
                <MobileNavItem to="/about" scrolled={scrolled}>
                  About
                </MobileNavItem>
                <MobileNavItem to="/careers" scrolled={scrolled}>
                  Careers
                </MobileNavItem>
                <MobileNavItem to="/blog" scrolled={scrolled}>
                  Blog
                </MobileNavItem>
                <Link
                  to="/contact"
                  className="block w-full px-4 py-2 rounded-md text-base font-medium text-center bg-primary-600 text-white hover:bg-primary-700"
                >
                  Contact Us
                </Link>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </header>
  )
}

Header.propTypes = {
  scrolled: PropTypes.bool.isRequired
}

export default Header