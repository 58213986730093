import React from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { FiHeadphones, FiTool, FiShield, FiClock } from 'react-icons/fi'

// Add these animation variants
const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
}

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
}

const Support = () => {
  // Add this ref
  const [headerRef, headerInView] = useInView({ threshold: 0.1, triggerOnce: true });
  
  const services = [
    {
      icon: <FiHeadphones className="w-8 h-8" />,
      title: "Technical Support",
      description: "24/7 technical support to help you resolve issues and keep your systems running smoothly.",
      features: [
        "24/7 availability",
        "Remote assistance",
        "Issue tracking",
        "Knowledge base access"
      ]
    },
    {
      icon: <FiTool className="w-8 h-8" />,
      title: "Maintenance Services",
      description: "Proactive maintenance and updates to prevent issues and optimize performance.",
      features: [
        "Regular maintenance",
        "System updates",
        "Performance optimization",
        "Preventive care"
      ]
    },
    {
      icon: <FiShield className="w-8 h-8" />,
      title: "Security Support",
      description: "Comprehensive security services to protect your systems and data.",
      features: [
        "Security monitoring",
        "Threat detection",
        "Vulnerability assessment",
        "Security updates"
      ]
    },
    {
      icon: <FiClock className="w-8 h-8" />,
      title: "Managed Services",
      description: "End-to-end IT management to handle all your technology needs.",
      features: [
        "IT infrastructure management",
        "Network monitoring",
        "Backup and recovery",
        "Service desk"
      ]
    }
  ]

  return (
    <div className="pt-20">
      {/* Header Section */}
      <section className="bg-primary-600 text-white py-16 md:py-24">
        <div className="container">
          <motion.div 
            ref={headerRef}
            initial="hidden"
            animate={headerInView ? "visible" : "hidden"}
            variants={staggerContainer}
            className="max-w-3xl mx-auto text-center"
          >
            <motion.h1 variants={fadeIn} className="text-4xl md:text-5xl font-bold mb-6">
              Support Services
            </motion.h1>
            <motion.p variants={fadeIn} className="text-lg md:text-xl text-white/80 mb-8">
              Dedicated technical support to keep your business running smoothly
            </motion.p>
          </motion.div>
        </div>
      </section>

      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-bold mb-4">IT Support Services</h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Comprehensive support solutions to keep your technology infrastructure running efficiently and securely.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          {services.map((service, index) => (
            <motion.div
              key={service.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white rounded-lg shadow-lg p-6"
            >
              <div className="text-primary-600 mb-4">
                {service.icon}
              </div>
              <h3 className="text-xl font-bold mb-3">{service.title}</h3>
              <p className="text-gray-600 mb-4">{service.description}</p>
              <ul className="space-y-2">
                {service.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center">
                    <svg className="w-5 h-5 mr-2 text-primary-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="bg-primary-600 text-white rounded-lg p-8 text-center"
        >
          <h2 className="text-2xl font-bold mb-4">Need Technical Support?</h2>
          <p className="text-white/80 mb-6">
            Our expert support team is ready to help you with any technical issues or questions.
          </p>
          <button className="btn btn-accent">
            Contact Support
          </button>
        </motion.div>
      </div>
    </div>
  )
}

export default Support 