import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { FiUsers, FiBookOpen, FiCode, FiArrowRight, FiStar } from 'react-icons/fi'

// Animation variants
const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
}

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
}

const Home = () => {
  const [heroRef, heroInView] = useInView({ threshold: 0.1, triggerOnce: true })
  const [servicesRef, servicesInView] = useInView({ threshold: 0.1, triggerOnce: true })
  const [testimonialsRef, testimonialsInView] = useInView({ threshold: 0.1, triggerOnce: true })
  const [ctaRef, ctaInView] = useInView({ threshold: 0.1, triggerOnce: true })

  return (
    <div>
      {/* Hero Section */}
      <section className="relative overflow-hidden bg-gradient-to-r from-primary-600 to-primary-800 text-white">
        <div className="absolute inset-0 opacity-10">
          <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <pattern id="grid" width="40" height="40" patternUnits="userSpaceOnUse">
                <path d="M 40 0 L 0 0 0 40" fill="none" stroke="white" strokeWidth="1"/>
              </pattern>
            </defs>
            <rect width="100%" height="100%" fill="url(#grid)" />
          </svg>
        </div>
        
        <div className="container relative py-20 md:py-28">
          <motion.div 
            ref={heroRef}
            initial="hidden"
            animate={heroInView ? "visible" : "hidden"}
            variants={staggerContainer}
            className="grid items-center grid-cols-1 gap-12 md:grid-cols-2"
          >
            <motion.div variants={fadeIn} className="max-w-lg">
              <h1 className="mb-6 text-4xl font-bold leading-tight md:text-5xl lg:text-6xl">
                Innovative Solutions for Your Business Growth
              </h1>
              <p className="mb-8 text-lg md:text-xl text-white/80">
                SBTech provides cutting-edge HR solutions, corporate training, and custom software development to help your business thrive in the digital age.
              </p>
              <div className="flex flex-wrap gap-4">
                <Link to="/contact" className="btn btn-accent">
                  Get Started
                </Link>
                <Link to="/services" className="btn btn-outline border-white text-white hover:bg-white/10">
                  Our Services
                </Link>
              </div>
            </motion.div>
            
            <motion.div variants={fadeIn} className="relative hidden md:block">
              <div className="relative overflow-hidden rounded-lg shadow-2xl">
                <img 
                  src="/images/hero-image.jpg" 
                  alt="Business professionals in a meeting" 
                  className="w-full h-auto"
                />
              </div>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Services Section */}
      <section className="section bg-gray-50">
        <div className="container">
          <motion.div 
            ref={servicesRef}
            initial="hidden"
            animate={servicesInView ? "visible" : "hidden"}
            variants={staggerContainer}
            className="text-center max-w-3xl mx-auto mb-16"
          >
            <motion.h2 variants={fadeIn} className="text-3xl md:text-4xl font-bold mb-4">
              Our Services
            </motion.h2>
            <motion.p variants={fadeIn} className="text-lg text-gray-600">
              We offer comprehensive solutions tailored to your business needs
            </motion.p>
          </motion.div>

          <motion.div 
            variants={staggerContainer}
            initial="hidden"
            animate={servicesInView ? "visible" : "hidden"}
            className="grid grid-cols-1 gap-8 md:grid-cols-3"
          >
            {/* HR/Recruitment Service */}
            <motion.div variants={fadeIn} className="card p-8 transition-all duration-300 hover:shadow-xl">
              <div className="flex items-center justify-center w-16 h-16 mb-6 rounded-full bg-primary-100 text-primary-600">
                <FiUsers className="w-8 h-8" />
              </div>
              <h3 className="mb-4 text-xl font-bold">HR & Recruitment</h3>
              <p className="mb-6 text-gray-600">
                Find and retain top talent with our comprehensive HR and recruitment solutions tailored to your company's needs.
              </p>
              <Link to="/services/hr-services" className="inline-flex items-center text-primary-600 hover:text-primary-700">
                Learn More <FiArrowRight className="ml-2" />
              </Link>
            </motion.div>

            {/* Corporate Training Service */}
            <motion.div variants={fadeIn} className="card p-8 transition-all duration-300 hover:shadow-xl">
              <div className="flex items-center justify-center w-16 h-16 mb-6 rounded-full bg-secondary-100 text-secondary-600">
                <FiBookOpen className="w-8 h-8" />
              </div>
              <h3 className="mb-4 text-xl font-bold">Corporate Training</h3>
              <p className="mb-6 text-gray-600">
                Enhance your team's skills with customized training programs designed to improve performance and productivity.
              </p>
              <Link to="/services/training" className="inline-flex items-center text-secondary-600 hover:text-secondary-700">
                Learn More <FiArrowRight className="ml-2" />
              </Link>
            </motion.div>

            {/* Software Development Service */}
            <motion.div variants={fadeIn} className="card p-8 transition-all duration-300 hover:shadow-xl">
              <div className="flex items-center justify-center w-16 h-16 mb-6 rounded-full bg-accent-100 text-accent-600">
                <FiCode className="w-8 h-8" />
              </div>
              <h3 className="mb-4 text-xl font-bold">Software Development</h3>
              <p className="mb-6 text-gray-600">
                Create custom software solutions that streamline your business processes and drive innovation.
              </p>
              <Link to="/services/software-development" className="inline-flex items-center text-accent-600 hover:text-accent-700">
                Learn More <FiArrowRight className="ml-2" />
              </Link>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="section bg-white">
        <div className="container">
          <motion.div 
            ref={testimonialsRef}
            initial="hidden"
            animate={testimonialsInView ? "visible" : "hidden"}
            variants={staggerContainer}
            className="text-center max-w-3xl mx-auto mb-16"
          >
            <motion.h2 variants={fadeIn} className="text-3xl md:text-4xl font-bold mb-4">
              What Our Clients Say
            </motion.h2>
            <motion.p variants={fadeIn} className="text-lg text-gray-600">
              Trusted by businesses across industries
            </motion.p>
          </motion.div>

          <motion.div 
            variants={staggerContainer}
            initial="hidden"
            animate={testimonialsInView ? "visible" : "hidden"}
            className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3"
          >
            {/* Testimonial 1 */}
            <motion.div variants={fadeIn} className="card p-8">
              <div className="flex mb-4 text-accent-500">
                {[...Array(5)].map((_, i) => (
                  <FiStar key={i} className="w-5 h-5 fill-current" />
                ))}
              </div>
              <p className="mb-6 italic text-gray-600">
                "SBTech helped us find exceptional talent that transformed our development team. Their recruitment process was thorough and efficient."
              </p>
              <div className="flex items-center">
                <div className="w-12 h-12 mr-4 overflow-hidden rounded-full bg-gray-200">
                  <img src="/images/testimonial-1.jpg" alt="Client" className="object-cover w-full h-full" />
                </div>
                <div>
                  <h4 className="font-bold">Sarah Johnson</h4>
                  <p className="text-sm text-gray-600">CTO, TechVision Inc.</p>
                </div>
              </div>
            </motion.div>

            {/* Testimonial 2 */}
            <motion.div variants={fadeIn} className="card p-8">
              <div className="flex mb-4 text-accent-500">
                {[...Array(5)].map((_, i) => (
                  <FiStar key={i} className="w-5 h-5 fill-current" />
                ))}
              </div>
              <p className="mb-6 italic text-gray-600">
                "The corporate training program developed by SBTech significantly improved our team's productivity and collaboration skills."
              </p>
              <div className="flex items-center">
                <div className="w-12 h-12 mr-4 overflow-hidden rounded-full bg-gray-200">
                  <img src="/images/testimonial-2.jpg" alt="Client" className="object-cover w-full h-full" />
                </div>
                <div>
                  <h4 className="font-bold">Michael Chen</h4>
                  <p className="text-sm text-gray-600">HR Director, Global Solutions</p>
                </div>
              </div>
            </motion.div>

            {/* Testimonial 3 */}
            <motion.div variants={fadeIn} className="card p-8">
              <div className="flex mb-4 text-accent-500">
                {[...Array(5)].map((_, i) => (
                  <FiStar key={i} className="w-5 h-5 fill-current" />
                ))}
              </div>
              <p className="mb-6 italic text-gray-600">
                "SBTech delivered a custom software solution that streamlined our operations and reduced costs by 30%. Highly recommended!"
              </p>
              <div className="flex items-center">
                <div className="w-12 h-12 mr-4 overflow-hidden rounded-full bg-gray-200">
                  <img src="/images/testimonial-3.jpg" alt="Client" className="object-cover w-full h-full" />
                </div>
                <div>
                  <h4 className="font-bold">Emily Rodriguez</h4>
                  <p className="text-sm text-gray-600">Operations Manager, Innovate Ltd</p>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="section bg-primary-600 text-white">
        <div className="container">
          <motion.div 
            ref={ctaRef}
            initial="hidden"
            animate={ctaInView ? "visible" : "hidden"}
            variants={staggerContainer}
            className="grid items-center grid-cols-1 gap-8 md:grid-cols-2"
          >
            <motion.div variants={fadeIn}>
              <h2 className="mb-4 text-3xl font-bold md:text-4xl">Ready to Transform Your Business?</h2>
              <p className="mb-6 text-lg text-white/80">
                Contact us today to discuss how SBTech can help you achieve your business goals with our tailored solutions.
              </p>
            </motion.div>
            <motion.div variants={fadeIn} className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 justify-end">
              <Link to="/contact" className="btn btn-accent">
                Contact Us
              </Link>
              <Link to="/case-studies" className="btn btn-outline border-white text-white hover:bg-white/10">
                View Case Studies
              </Link>
            </motion.div>
          </motion.div>
        </div>
      </section>
    </div>
  )
}

export default Home