import React from 'react'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { FiTarget, FiEye, FiAward } from 'react-icons/fi'

// Animation variants
const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
}

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
}

const About = () => {
  const [headerRef, headerInView] = useInView({ threshold: 0.1, triggerOnce: true })
  const [missionRef, missionInView] = useInView({ threshold: 0.1, triggerOnce: true })
  const [teamRef, teamInView] = useInView({ threshold: 0.1, triggerOnce: true })
  const [statsRef, statsInView] = useInView({ threshold: 0.1, triggerOnce: true })

  return (
    <div className="pt-20"> {/* Add padding top to account for fixed header */}
      {/* Header Section */}
      <section className="bg-primary-600 text-white py-16 md:py-24">
        <div className="container">
          <motion.div 
            ref={headerRef}
            initial="hidden"
            animate={headerInView ? "visible" : "hidden"}
            variants={staggerContainer}
            className="max-w-3xl mx-auto text-center"
          >
            <motion.h1 variants={fadeIn} className="text-4xl md:text-5xl font-bold mb-6">
              About SBTech
            </motion.h1>
            <motion.p variants={fadeIn} className="text-lg md:text-xl text-white/80 mb-8">
              We're a team of passionate professionals dedicated to helping businesses grow through innovative HR, training, and technology solutions
            </motion.p>
          </motion.div>
        </div>
      </section>

      {/* Company Story Section */}
      <section className="section bg-white">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold mb-6">Our Story</h2>
              <p className="text-gray-600 mb-4">
                Founded in 2015, SBTech began with a simple mission: to help businesses find and develop the talent they need to succeed in an increasingly competitive marketplace.
              </p>
              <p className="text-gray-600 mb-4">
                What started as a small HR consulting firm has grown into a comprehensive business solutions provider, offering services in recruitment, corporate training, and custom software development.
              </p>
              <p className="text-gray-600">
                Today, we're proud to serve clients across various industries throughout Canada, helping them build stronger teams, develop essential skills, and leverage technology to drive growth and innovation.
              </p>
            </div>
            <div className="rounded-xl overflow-hidden shadow-card">
              <img 
                src="/images/about-company.jpg" 
                alt="SBTech office with team members collaborating" 
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Mission, Vision, Values Section */}
      <section className="section bg-gray-50">
        <div className="container">
          <motion.div 
            ref={missionRef}
            initial="hidden"
            animate={missionInView ? "visible" : "hidden"}
            variants={staggerContainer}
            className="text-center max-w-3xl mx-auto mb-16"
          >
            <motion.h2 variants={fadeIn} className="text-3xl md:text-4xl font-bold mb-4">
              Our Mission, Vision & Values
            </motion.h2>
            <motion.p variants={fadeIn} className="text-lg text-gray-600">
              The principles that guide everything we do
            </motion.p>
          </motion.div>

          <motion.div 
            variants={staggerContainer}
            initial="hidden"
            animate={missionInView ? "visible" : "hidden"}
            className="grid grid-cols-1 gap-8 md:grid-cols-3"
          >
            {/* Mission */}
            <motion.div variants={fadeIn} className="card p-8 text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-6 rounded-full bg-primary-100 text-primary-600">
                <FiTarget className="w-8 h-8" />
              </div>
              <h3 className="mb-4 text-xl font-bold">Our Mission</h3>
              <p className="text-gray-600">
                To empower businesses with the talent, skills, and technology they need to achieve sustainable growth and success in today's dynamic marketplace.
              </p>
            </motion.div>

            {/* Vision */}
            <motion.div variants={fadeIn} className="card p-8 text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-6 rounded-full bg-secondary-100 text-secondary-600">
                <FiEye className="w-8 h-8" />
              </div>
              <h3 className="mb-4 text-xl font-bold">Our Vision</h3>
              <p className="text-gray-600">
                To be the leading provider of integrated business solutions in Canada, known for our expertise, innovation, and commitment to client success.
              </p>
            </motion.div>

            {/* Values */}
            <motion.div variants={fadeIn} className="card p-8 text-center">
              <div className="flex items-center justify-center w-16 h-16 mx-auto mb-6 rounded-full bg-accent-100 text-accent-600">
                <FiAward className="w-8 h-8" />
              </div>
              <h3 className="mb-4 text-xl font-bold">Our Values</h3>
              <p className="text-gray-600">
                Excellence, integrity, innovation, collaboration, and client-centricity form the foundation of our approach to every project and relationship.
              </p>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Team Section */}
      <section className="section bg-white">
        <div className="container">
          <motion.div 
            ref={teamRef}
            initial="hidden"
            animate={teamInView ? "visible" : "hidden"}
            variants={staggerContainer}
            className="text-center max-w-3xl mx-auto mb-16"
          >
            <motion.h2 variants={fadeIn} className="text-3xl md:text-4xl font-bold mb-4">
              Meet Our Leadership Team
            </motion.h2>
            <motion.p variants={fadeIn} className="text-lg text-gray-600">
              Experienced professionals dedicated to your success
            </motion.p>
          </motion.div>

          <motion.div 
            variants={staggerContainer}
            initial="hidden"
            animate={teamInView ? "visible" : "hidden"}
            className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4"
          >
            {/* Team Member 1 */}
            <motion.div variants={fadeIn} className="text-center">
              <div className="relative mb-6 overflow-hidden rounded-xl aspect-square">
                <img 
                  src="/images/team-1.jpg" 
                  alt="Assad Khan - CEO & Founder" 
                  className="object-cover w-full h-full"
                />
              </div>
              <h3 className="mb-1 text-xl font-bold">Assad Khan</h3>
              <p className="mb-3 text-primary-600 font-medium">CEO & Founder</p>
              <p className="text-gray-600">
                With over 20 years of experience in HR and business consulting, Assad founded SBTech with a vision to transform how businesses approach talent and technology.
              </p>
            </motion.div>

            {/* Team Member 2 */}
            <motion.div variants={fadeIn} className="text-center">
              <div className="relative mb-6 overflow-hidden rounded-xl aspect-square">
                <img 
                  src="/images/team-2.jpg" 
                  alt="Jennifer Lee - Director of HR Services" 
                  className="object-cover w-full h-full"
                />
              </div>
              <h3 className="mb-1 text-xl font-bold">Jennifer Lee</h3>
              <p className="mb-3 text-primary-600 font-medium">Director of HR Services</p>
              <p className="text-gray-600">
                Jennifer brings extensive expertise in talent acquisition and HR strategy, helping our clients build high-performing teams that drive business success.
              </p>
            </motion.div>

            {/* Team Member 3 */}
            <motion.div variants={fadeIn} className="text-center">
              <div className="relative mb-6 overflow-hidden rounded-xl aspect-square">
                <img 
                  src="/images/team-3.jpg" 
                  alt="David Rodriguez - Head of Training" 
                  className="object-cover w-full h-full"
                />
              </div>
              <h3 className="mb-1 text-xl font-bold">David Rodriguez</h3>
              <p className="mb-3 text-primary-600 font-medium">Head of Training</p>
              <p className="text-gray-600">
                David is passionate about learning and development, creating innovative training programs that empower employees and drive organizational growth.
              </p>
            </motion.div>

            {/* Team Member 4 */}
            <motion.div variants={fadeIn} className="text-center">
              <div className="relative mb-6 overflow-hidden rounded-xl aspect-square">
                <img 
                  src="/images/team-4.jpg" 
                  alt="Deepti Singh - CTO" 
                  className="object-cover w-full h-full"
                />
              </div>
              <h3 className="mb-1 text-xl font-bold">Deepti Singh</h3>
              <p className="mb-3 text-primary-600 font-medium">CTO</p>
              <p className="text-gray-600">
                Deepti leads our software development team, leveraging her technical expertise to create custom solutions that help businesses streamline operations and drive innovation.
              </p>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="section bg-primary-600 text-white">
        <div className="container">
          <motion.div 
            ref={statsRef}
            initial="hidden"
            animate={statsInView ? "visible" : "hidden"}
            variants={staggerContainer}
            className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4"
          >
            {/* Stat 1 */}
            <motion.div variants={fadeIn} className="text-center">
              <div className="text-5xl font-bold mb-2">8+</div>
              <p className="text-xl">Years of Experience</p>
            </motion.div>

            {/* Stat 2 */}
            <motion.div variants={fadeIn} className="text-center">
              <div className="text-5xl font-bold mb-2">200+</div>
              <p className="text-xl">Clients Served</p>
            </motion.div>

            {/* Stat 3 */}
            <motion.div variants={fadeIn} className="text-center">
              <div className="text-5xl font-bold mb-2">500+</div>
              <p className="text-xl">Projects Completed</p>
            </motion.div>

            {/* Stat 4 */}
            <motion.div variants={fadeIn} className="text-center">
              <div className="text-5xl font-bold mb-2">30+</div>
              <p className="text-xl">Team Members</p>
            </motion.div>
          </motion.div>
        </div>
      </section>
    </div>
  )
}

export default About