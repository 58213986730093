import React from 'react'
import { motion } from 'framer-motion'
import { FiBriefcase, FiMapPin, FiClock, FiDollarSign } from 'react-icons/fi'
import { Link } from 'react-router-dom'

const Careers = () => {
  const openPositions = [
    {
      id: 1,
      title: "Senior HR Consultant",
      department: "HR Services",
      location: "Toronto, ON",
      type: "Full-time",
      salary: "$80,000 - $100,000",
      description: "We're looking for an experienced HR consultant to join our team and help clients transform their HR processes.",
      requirements: [
        "5+ years of HR consulting experience",
        "Strong knowledge of HR best practices",
        "Excellent communication and client management skills",
        "Bachelor's degree in HR or related field"
      ]
    },
    {
      id: 2,
      title: "Software Developer",
      department: "Software Development",
      location: "Remote",
      type: "Full-time",
      salary: "$90,000 - $120,000",
      description: "Join our development team to create innovative software solutions for our clients.",
      requirements: [
        "3+ years of software development experience",
        "Strong knowledge of React and Node.js",
        "Experience with cloud platforms (AWS/Azure)",
        "Bachelor's degree in Computer Science or related field"
      ]
    },
    {
      id: 3,
      title: "Training Specialist",
      department: "Corporate Training",
      location: "Toronto, ON",
      type: "Full-time",
      salary: "$70,000 - $90,000",
      description: "Help organizations develop their workforce through effective training programs.",
      requirements: [
        "4+ years of corporate training experience",
        "Strong presentation and facilitation skills",
        "Experience with e-learning platforms",
        "Bachelor's degree in Education or related field"
      ]
    }
  ]

  return (
    <div className="pt-24 pb-16">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-bold mb-4">Careers at SBTech</h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Join our team of professionals and help us shape the future of business technology solutions.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {openPositions.map((position, index) => (
            <motion.div
              key={position.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white rounded-lg shadow-lg p-6"
            >
              <h3 className="text-xl font-bold mb-2">{position.title}</h3>
              <div className="flex items-center text-gray-600 mb-4">
                <FiBriefcase className="mr-2" />
                <span>{position.department}</span>
              </div>
              <div className="flex items-center text-gray-600 mb-4">
                <FiMapPin className="mr-2" />
                <span>{position.location}</span>
              </div>
              <div className="flex items-center text-gray-600 mb-4">
                <FiClock className="mr-2" />
                <span>{position.type}</span>
              </div>
              <div className="flex items-center text-gray-600 mb-6">
                <FiDollarSign className="mr-2" />
                <span>{position.salary}</span>
              </div>
              <p className="text-gray-700 mb-4">{position.description}</p>
              <h4 className="font-semibold mb-2">Requirements:</h4>
              <ul className="space-y-2 mb-6">
                {position.requirements.map((req, idx) => (
                  <li key={idx} className="flex items-start">
                    <svg className="w-5 h-5 mr-2 text-primary-500 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span>{req}</span>
                  </li>
                ))}
              </ul>
              <div className="mt-6">
                <Link 
                  to="/contact" 
                  className="btn btn-primary"
                >
                  Apply Now
                </Link>
              </div>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="mt-16 text-center"
        >
          <h2 className="text-2xl font-bold mb-4">Don't See the Right Position?</h2>
          <p className="text-gray-600 mb-6">
            We're always looking for talented individuals. Send us your resume and we'll keep you in mind for future opportunities.
          </p>
          <button className="btn btn-outline">
            Submit Resume
          </button>
        </motion.div>
      </div>
    </div>
  )
}

export default Careers