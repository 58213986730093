import React from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { FiCalendar, FiUser, FiTag } from 'react-icons/fi'

const Blog = () => {
  const blogPosts = [
    {
      id: 1,
      title: "The Future of HR and Recruitment",
      excerpt: "Explore how AI, data analytics, and emerging workplace trends are transforming HR and recruitment practices in 2024.",
      author: "Jennifer Lee",
      date: "March 15, 2024",
      category: "HR & Recruitment",
      image: "/images/blog/hr-future.jpg",
      readTime: "5 min read",
      slug: "future-of-hr-recruitment"
    },
    {
      id: 2,
      title: "Effective Employee Training Strategies",
      excerpt: "Learn proven methods to create engaging and impactful training programs for your workforce.",
      author: "David Rodriguez",
      date: "March 10, 2024",
      category: "Corporate Training",
      image: "/images/blog/training-strategies.jpg",
      readTime: "7 min read",
      slug: "effective-training-strategies"
    },
    {
      id: 3,
      title: "Custom Software Solutions for Small Businesses",
      excerpt: "Discover how tailored software solutions can help small businesses compete in the digital age.",
      author: "Deepti Singh",
      date: "March 5, 2024",
      category: "Software Development",
      image: "/images/blog/software-solutions.jpg",
      readTime: "6 min read",
      slug: "custom-software-solutions"
    }
  ]

  return (
    <div className="pt-24 pb-16">
        <div className="container">
          <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-bold mb-4">Blog</h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Insights, trends, and best practices in HR, training, and technology.
          </p>
            </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((post, index) => (
            <motion.article
              key={post.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <div className="aspect-w-16 aspect-h-9 bg-gray-200">
                <img
                  src={post.image}
                  alt={post.title}
                  className="object-cover w-full h-full"
                />
              </div>
              <div className="p-6">
                <div className="flex items-center text-sm text-gray-500 mb-4">
                  <span className="flex items-center mr-4">
                    <FiCalendar className="mr-1" />
                    {post.date}
                  </span>
                  <span className="flex items-center mr-4">
                    <FiUser className="mr-1" />
                    {post.author}
                  </span>
                  <span className="flex items-center">
                    <FiTag className="mr-1" />
                    {post.category}
                  </span>
                </div>
                <h2 className="text-xl font-bold mb-3">{post.title}</h2>
                <p className="text-gray-600 mb-4">{post.excerpt}</p>
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-500">{post.readTime}</span>
                  <Link 
                    to={`/blog/${post.slug}`} 
                    className="text-primary-600 hover:text-primary-700 font-medium"
                  >
                    Read More →
                  </Link>
                </div>
              </div>
            </motion.article>
          ))}
        </div>

          <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="mt-16 text-center"
        >
          <h2 className="text-2xl font-bold mb-4">Stay Updated</h2>
          <p className="text-gray-600 mb-6">
            Subscribe to our newsletter to receive the latest blog posts and industry insights.
          </p>
          <Link to="/contact" className="btn btn-primary">
            Subscribe Now
                </Link>
          </motion.div>
        </div>
    </div>
  )
}

export default Blog