import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FiMail, FiPhone, FiMapPin, FiLinkedin, FiTwitter, FiFacebook, FiInstagram, FiSend } from 'react-icons/fi'
import Logo from '../ui/Logo'

const FooterLink = ({ to, children }) => (
  <Link
    to={to}
    className="text-gray-400 hover:text-white transition-colors duration-200"
  >
    {children}
  </Link>
)

const SocialLink = ({ href, icon: Icon }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="text-gray-400 hover:text-white transition-colors duration-200"
  >
    <Icon className="w-5 h-5" />
  </a>
)

FooterLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

SocialLink.propTypes = {
  href: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired
}

const Footer = () => {
  const currentYear = new Date().getFullYear()
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState('')
  
  const handleSubmit = (e) => {
    e.preventDefault()
    // Simulate API call
    setStatus('success')
    setEmail('')
    setTimeout(() => setStatus(''), 3000)
  }
  
  return (
    <footer className="bg-gray-900 text-white pt-16 pb-8">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
          {/* Company Info */}
          <div>
            <Logo color="light" className="mb-6" />
            <p className="text-gray-400 mb-6">
              Empowering businesses with innovative technology solutions and expert consulting services.
            </p>
            <div className="flex space-x-4">
              <SocialLink href="https://linkedin.com" icon={FiLinkedin} />
              <SocialLink href="https://twitter.com" icon={FiTwitter} />
              <SocialLink href="https://facebook.com" icon={FiFacebook} />
              <SocialLink href="https://instagram.com" icon={FiInstagram} />
            </div>
          </div>
          
          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-bold mb-6">Quick Links</h3>
            <ul className="space-y-3">
              <li><FooterLink to="/">Home</FooterLink></li>
              <li><FooterLink to="/services">Services</FooterLink></li>
              <li><FooterLink to="/about">About Us</FooterLink></li>
              <li><FooterLink to="/case-studies">Case Studies</FooterLink></li>
              <li><FooterLink to="/careers">Careers</FooterLink></li>
              <li><FooterLink to="/blog">Blog</FooterLink></li>
              <li><FooterLink to="/contact">Contact</FooterLink></li>
            </ul>
          </div>
          
          {/* Services */}
          <div>
            <h3 className="text-lg font-bold mb-6">Our Services</h3>
            <ul className="space-y-3">
              <li><FooterLink to="/services/software-development">Software Development</FooterLink></li>
              <li><FooterLink to="/services/consulting">Consulting</FooterLink></li>
              <li><FooterLink to="/services/hr-services">HR Services</FooterLink></li>
              <li><FooterLink to="/services/training">Training</FooterLink></li>
              <li><FooterLink to="/services/support">Support</FooterLink></li>
            </ul>
          </div>
          
          {/* Contact Info */}
          <div>
            <h3 className="text-lg font-bold mb-6">Contact Us</h3>
            <ul className="space-y-4">
              <li className="flex items-start">
                <FiMapPin className="mt-1 mr-3 text-primary-400" />
                <span>22 Albert St, Langton, ON<br />N0E 1G0, Canada</span>
              </li>
              <li className="flex items-center">
                <FiPhone className="mr-3 text-primary-400" />
                <a href="tel:+14168023794" className="hover:text-primary-300 transition-colors">
                  (416) 802-3794
                </a>
              </li>
              <li className="flex items-center">
                <FiMail className="mr-3 text-primary-400" />
                <a href="mailto:info@sbtech.ca" className="hover:text-primary-300 transition-colors">
                  info@sbtech.ca
                </a>
              </li>
            </ul>
          </div>
        </div>
        
        {/* Newsletter */}
        <div className="border-t border-gray-800 pt-8 pb-12">
          <div className="max-w-xl mx-auto text-center">
            <h3 className="text-lg font-bold mb-4">Subscribe to Our Newsletter</h3>
            <p className="text-gray-400 mb-6">Stay updated with the latest industry insights and company news</p>
            <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-3">
              <input 
                type="email" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Your email address" 
                className="flex-grow px-4 py-3 rounded-lg bg-gray-800 border border-gray-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:border-transparent"
                required
              />
              <button 
                type="submit" 
                className="btn btn-primary whitespace-nowrap"
              >
                <FiSend className="w-5 h-5 mr-2" />
                Subscribe
              </button>
            </form>
            {status === 'success' && (
              <p className="mt-4 text-green-400">Thank you for subscribing!</p>
            )}
          </div>
        </div>
        
        {/* Legal Links */}
        <div className="mt-8 border-t border-gray-700 pt-8">
          <div className="flex flex-wrap justify-center gap-8 text-sm text-gray-400">
            <Link to="/privacy-policy" className="hover:text-white transition-colors duration-200">
              Privacy Policy
            </Link>
            <Link to="/terms-of-service" className="hover:text-white transition-colors duration-200">
              Terms of Service
            </Link>
          </div>
        </div>
        
        {/* Copyright */}
        <div className="border-t border-gray-800 pt-8 text-center text-gray-500 text-sm">
          <p>© {currentYear} SBTech. All rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer