import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { FiCode, FiCheck, FiArrowRight, FiSmartphone, FiCloud, FiShield } from 'react-icons/fi'

// Animation variants
const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
}

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
}

const SoftwareDev = () => {
  const [headerRef, headerInView] = useInView({ threshold: 0.1, triggerOnce: true })
  const [servicesRef, servicesInView] = useInView({ threshold: 0.1, triggerOnce: true })
  const [processRef, processInView] = useInView({ threshold: 0.1, triggerOnce: true })
  const [ctaRef, ctaInView] = useInView({ threshold: 0.1, triggerOnce: true })

  const services = [
    {
      icon: <FiCode className="w-8 h-8" />,
      title: "Custom Software Development",
      description: "Tailored software solutions designed to meet your specific business needs and objectives.",
      features: [
        "Web applications",
        "Desktop software",
        "API development",
        "Legacy system modernization"
      ]
    },
    {
      icon: <FiSmartphone className="w-8 h-8" />,
      title: "Mobile App Development",
      description: "Native and cross-platform mobile applications for iOS and Android platforms.",
      features: [
        "iOS development",
        "Android development",
        "Cross-platform solutions",
        "App maintenance"
      ]
    },
    {
      icon: <FiCloud className="w-8 h-8" />,
      title: "Cloud Solutions",
      description: "Cloud-based applications and infrastructure to scale your business operations.",
      features: [
        "Cloud migration",
        "Serverless architecture",
        "Microservices",
        "Cloud optimization"
      ]
    },
    {
      icon: <FiShield className="w-8 h-8" />,
      title: "Cybersecurity",
      description: "Comprehensive security solutions to protect your digital assets and data.",
      features: [
        "Security audits",
        "Penetration testing",
        "Security monitoring",
        "Compliance solutions"
      ]
    }
  ]

  return (
    <div className="pt-24 pb-16">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-bold mb-4">Software Development Services</h1>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Innovative software solutions to help your business thrive in the digital age.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          {services.map((service, index) => (
            <motion.div
              key={service.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="bg-white rounded-lg shadow-lg p-6"
            >
              <div className="text-primary-600 mb-4">
                {service.icon}
              </div>
              <h3 className="text-xl font-bold mb-3">{service.title}</h3>
              <p className="text-gray-600 mb-4">{service.description}</p>
              <ul className="space-y-2">
                {service.features.map((feature, idx) => (
                  <li key={idx} className="flex items-center">
                    <svg className="w-5 h-5 mr-2 text-primary-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="bg-primary-600 text-white rounded-lg p-8 text-center"
        >
          <h2 className="text-2xl font-bold mb-4">Ready to Build Your Digital Solution?</h2>
          <p className="text-white/80 mb-6">
            Let's discuss how our software development services can help your business grow.
          </p>
          <button className="btn btn-accent">
            Start Your Project
          </button>
        </motion.div>
      </div>
    </div>
  )
}

export default SoftwareDev;