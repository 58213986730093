import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const Logo = ({ color = 'dark', className = '' }) => {
  const fillColor = color === 'dark' ? '#0f172a' : '#ffffff'
  const accentColor = '#00c3ff' // Secondary brand color
  
  return (
    <Link to="/" className={`flex items-center ${className}`}>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 0C8.954 0 0 8.954 0 20C0 31.046 8.954 40 20 40C31.046 40 40 31.046 40 20C40 8.954 31.046 0 20 0ZM20 36C11.163 36 4 28.837 4 20C4 11.163 11.163 4 20 4C28.837 4 36 11.163 36 20C36 28.837 28.837 36 20 36Z" fill={accentColor}/>
        <path d="M28 12H12V16H28V12Z" fill={fillColor}/>
        <path d="M28 18H12V22H28V18Z" fill={fillColor}/>
        <path d="M20 24H12V28H20V24Z" fill={fillColor}/>
      </svg>
      <span className={`ml-2 text-xl font-heading font-bold ${color === 'dark' ? 'text-gray-900' : 'text-white'}`}>SBTech</span>
    </Link>
  )
}

Logo.propTypes = {
  color: PropTypes.oneOf(['dark', 'light']),
  className: PropTypes.string
}

Logo.defaultProps = {
  color: 'dark',
  className: ''
}

export default Logo