import React from 'react'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { FiCode, FiUsers, FiBookOpen, FiSettings, FiBriefcase, FiShield } from 'react-icons/fi'

// Animation variants
const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.6 } }
}

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
}

const Services = () => {
  const [headerRef, headerInView] = useInView({ threshold: 0.1, triggerOnce: true })
  const [servicesRef, servicesInView] = useInView({ threshold: 0.1, triggerOnce: true })
  const [ctaRef, ctaInView] = useInView({ threshold: 0.1, triggerOnce: true })

  return (
    <div className="pt-20">
      {/* Header Section */}
      <section className="bg-primary-600 text-white py-16 md:py-24">
        <div className="container">
          <motion.div 
            ref={headerRef}
            initial="hidden"
            animate={headerInView ? "visible" : "hidden"}
            variants={staggerContainer}
            className="max-w-3xl mx-auto text-center"
          >
            <motion.h1 variants={fadeIn} className="text-4xl md:text-5xl font-bold mb-6">
              Our Services
            </motion.h1>
            <motion.p variants={fadeIn} className="text-lg md:text-xl text-white/80 mb-8">
              Comprehensive solutions tailored to help your business grow and succeed in today's competitive market
            </motion.p>
          </motion.div>
        </div>
      </section>

      {/* Services Section */}
      <section className="section bg-white">
        <div className="container">
          <motion.div 
            ref={servicesRef}
            initial="hidden"
            animate={servicesInView ? "visible" : "hidden"}
            variants={staggerContainer}
            className="grid grid-cols-1 gap-16"
          >
            {/* Software Development */}
            <motion.div variants={fadeIn} className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div className="order-2 md:order-1">
                <h2 className="text-3xl font-bold mb-4">Software Development</h2>
                <p className="text-gray-600 mb-6">
                  Our software development team creates custom solutions that streamline your business processes and drive innovation. We work closely with you to understand your unique requirements and deliver high-quality software that meets your needs.
                </p>
                <ul className="space-y-3 mb-6">
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>Custom web and mobile applications</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>Enterprise software solutions</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>Cloud-based applications</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>API development and integration</span>
                  </li>
                </ul>
                <Link to="/services/software-development" className="btn btn-primary">
                  Learn More <FiCode className="ml-2" />
                </Link>
              </div>
              <div className="order-1 md:order-2">
                <div className="rounded-xl overflow-hidden shadow-card">
                  <img 
                    src="/images/software-development.jpg" 
                    alt="Software Development Services (1200x800)" 
                    className="w-full h-auto"
                  />
                </div>
              </div>
            </motion.div>

            {/* Consulting Services */}
            <motion.div variants={fadeIn} className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div className="order-2">
                <h2 className="text-3xl font-bold mb-4">Consulting Services</h2>
                <p className="text-gray-600 mb-6">
                  Our consulting team provides strategic guidance and practical solutions to help your business overcome challenges and achieve its goals. We offer expertise across various domains to drive your success.
                </p>
                <ul className="space-y-3 mb-6">
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>Business strategy development</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>Digital transformation</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>Process optimization</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>Technology consulting</span>
                  </li>
                </ul>
                <Link to="/services/consulting" className="btn btn-primary">
                  Learn More <FiBriefcase className="ml-2" />
                </Link>
              </div>
              <div className="order-1">
                <div className="rounded-xl overflow-hidden shadow-card">
                  <img 
                    src="/images/consulting.jpg" 
                    alt="Consulting Services (1200x800)" 
                    className="w-full h-auto"
                  />
                </div>
              </div>
            </motion.div>

            {/* HR Services */}
            <motion.div variants={fadeIn} className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div className="order-2 md:order-1">
                <h2 className="text-3xl font-bold mb-4">HR Services</h2>
                <p className="text-gray-600 mb-6">
                  Our comprehensive HR services help you build and maintain a strong, engaged workforce. From recruitment to retention, we provide the expertise you need to manage your most valuable asset - your people.
                </p>
                <ul className="space-y-3 mb-6">
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>Talent acquisition and recruitment</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>HR policy development</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>Performance management</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>Employee relations</span>
                  </li>
                </ul>
                <Link to="/services/hr-services" className="btn btn-primary">
                  Learn More <FiUsers className="ml-2" />
                </Link>
              </div>
              <div className="order-1 md:order-2">
                <div className="rounded-xl overflow-hidden shadow-card">
                  <img 
                    src="/images/hr-services.jpg" 
                    alt="HR Services (1200x800)" 
                    className="w-full h-auto"
                  />
                </div>
              </div>
            </motion.div>

            {/* Training Services */}
            <motion.div variants={fadeIn} className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div className="order-2">
                <h2 className="text-3xl font-bold mb-4">Training Services</h2>
                <p className="text-gray-600 mb-6">
                  Enhance your team's capabilities with our comprehensive training programs. We offer customized training solutions designed to improve skills, boost productivity, and drive success.
                </p>
                <ul className="space-y-3 mb-6">
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>Leadership development</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>Technical skills training</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>Professional development</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>Compliance training</span>
                  </li>
                </ul>
                <Link to="/services/training" className="btn btn-primary">
                  Learn More <FiBookOpen className="ml-2" />
                </Link>
              </div>
              <div className="order-1">
                <div className="rounded-xl overflow-hidden shadow-card">
                  <img 
                    src="/images/training.jpg" 
                    alt="Training Services (1200x800)" 
                    className="w-full h-auto"
                  />
                </div>
              </div>
            </motion.div>

            {/* Support Services */}
            <motion.div variants={fadeIn} className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
              <div className="order-2 md:order-1">
                <h2 className="text-3xl font-bold mb-4">Support Services</h2>
                <p className="text-gray-600 mb-6">
                  Our dedicated support team ensures your systems and solutions run smoothly. We provide comprehensive technical support and maintenance services to keep your business operating at peak efficiency.
                </p>
                <ul className="space-y-3 mb-6">
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>24/7 technical support</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>System maintenance</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>Performance monitoring</span>
                  </li>
                  <li className="flex items-start">
                    <span className="text-primary-500 mr-2">✓</span>
                    <span>Security updates</span>
                  </li>
                </ul>
                <Link to="/services/support" className="btn btn-primary">
                  Learn More <FiSettings className="ml-2" />
                </Link>
              </div>
              <div className="order-1 md:order-2">
                <div className="rounded-xl overflow-hidden shadow-card">
                  <img 
                    src="/images/support.jpg" 
                    alt="Support Services (1200x800)" 
                    className="w-full h-auto"
                  />
                </div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="section bg-gray-50">
        <div className="container">
          <motion.div 
            ref={ctaRef}
            initial="hidden"
            animate={ctaInView ? "visible" : "hidden"}
            variants={staggerContainer}
            className="max-w-3xl mx-auto text-center"
          >
            <motion.h2 variants={fadeIn} className="text-3xl md:text-4xl font-bold mb-4">
              Ready to Transform Your Business?
            </motion.h2>
            <motion.p variants={fadeIn} className="text-lg text-gray-600 mb-8">
              Contact us today to discuss how our services can help you achieve your business goals.
            </motion.p>
            <motion.div variants={fadeIn} className="flex flex-col sm:flex-row justify-center gap-4">
              <Link to="/contact" className="btn btn-primary">
                Get in Touch
              </Link>
              <Link to="/case-studies" className="btn btn-outline">
                View Our Work
              </Link>
            </motion.div>
          </motion.div>
        </div>
      </section>
    </div>
  )
}

export default Services